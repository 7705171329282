<template>
  <div class="card theme-card pt-5 pb-5 pl-3 pr-3 pl-md-5 pr-md-5">
    <h2>Medical Diagnosis</h2>
    <form @submit.prevent="updateProfile">
      <input-control
        type="horizontal"
        v-model="patientData.primary_care_physician"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Primary Care Physician</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.physician_type"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Physician Type</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.physician_phone"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Physician Phone</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.physician_email"
        control="email"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Physician Email
      </input-control>
      <input-control
        type="horizontal"
        v-model="patientData.preferred_hospital"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Preferred Hospital</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.hospital_email"
        control="email"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Hospital Email</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.preferred_home_health_agency"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Preferred Home Health Agency</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.home_health_agency_email"
        control="email"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Home Health Agency Email
      </input-control>
      <input-control
        type="horizontal"
        v-model="patientData.preferred_hospice_agency"
        control="text"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Preferred Hospice Agency</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.hospice_email"
        control="email"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Hospice Email</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.diagnosis"
        control="textarea"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Diagnosis</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.medical_notes"
        control="textarea"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Medical Notes</input-control
      >
      <input-control
        type="horizontal"
        v-model="patientData.pharmacy_medication_information"
        control="textarea"
        class="input-control"
        labelClass="col-md-3 col-lg-2"
        :disabled="!user || !(user.isAdmin || user.isManagingAdmin)"
        >Pharmacy/Medication Information</input-control
      >
      <save
        :saving="isSaving"
        type="submit"
        classes="save-button btn btn-theme"
        v-if="
          user &&
          (user.isAdmin || user.isManagingAdmin) &&
          $can({ key: 'clients', expectedPermission: 'edit' })
        "
        >Update</save
      >
    </form>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PatientDiagnosis",
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patientData: (state) => state.patients.patient,
    }),
  },
  methods: {
    ...mapActions({
      updatePatient: "patients/update",
    }),
    updateProfile: function () {
      this.isSaving = true;
      this.updatePatient({
        patient: this.patientData.id,
        first: this.patientData.first,
        last: this.patientData.last,
        phone_number: this.patientData.phone_number,
        sin: this.patientData.sin
          ? this.patientData.sin.replace(/[^0-9]\s+/gi, "")
          : "",
        healthcard: this.patientData.healthcard
          ? this.patientData.healthcard.replace(/[^0-9]\s+/gi, "")
          : "",
        injured_at: this.patientData.injured_at || null,
        referred_by: this.patientData.referred_by,
        status: this.patientData.status,
        funding_source: this.patientData.funding_source,
        diagnosis: this.patientData.diagnosis,
        claim_number: this.patientData.claim_number,
        email: this.patientData.email,
        ...(this.patientData.administrator_id && {
          administrator_id: this.patientData.administrator_id.id,
        }),
        primary_care_physician: this.patientData.primary_care_physician,
        physician_type: this.patientData.physician_type,
        physician_phone: this.patientData.physician_phone,
        physician_email: this.patientData.physician_email,
        preferred_hospital: this.patientData.preferred_hospital,
        hospital_email: this.patientData.hospital_email,
        preferred_home_health_agency:
          this.patientData.preferred_home_health_agency,
        home_health_agency_email: this.patientData.home_health_agency_email,
        preferred_hospice_agency: this.patientData.preferred_hospice_agency,
        hospice_email: this.patientData.hospice_email,
        medical_notes: this.patientData.medical_notes,
        pharmacy_medication_information:
          this.patientData.pharmacy_medication_information,
      }).then(() => {
        this.isSaving = false;
      });
    },
  },
};
</script>

<style>
.input-control .col-form-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
